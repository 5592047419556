import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter  } from 'react-router-dom'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { App as AppAntD } from 'antd';
import store from './store';
import history from './history'
import Layouts from './layouts'
import { GOOGLE_API_CLIENT_ID, HOST_URL, THEME_CONFIG } from './configs/AppConfig';
import './lang'
import { IntlProvider } from 'react-intl';
import AppLocale from 'lang/intl';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './index.css'
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication, BrowserCacheLocation } from '@azure/msal-browser';

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const pca = new PublicClientApplication({
  auth: {
    clientId: 'b58a2704-6560-4a33-928e-92463d830193',
    redirectUri: HOST_URL, // Update with your redirect URL
    scope: 'Calendars.Read',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage
  }
});

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter history={history}>
          <MsalProvider instance={pca}>
            <GoogleOAuthProvider clientId={GOOGLE_API_CLIENT_ID}>
              <IntlProvider locale={AppLocale[THEME_CONFIG.locale].locale} messages={AppLocale[THEME_CONFIG.locale].messages}>
                <AppAntD>
                  <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
                    <Layouts />
                  </ThemeSwitcherProvider>
                </AppAntD>
              </IntlProvider>
            </GoogleOAuthProvider>
          </MsalProvider>
        </BrowserRouter>  
      </Provider>
    </div>
  );
}

export default App;
